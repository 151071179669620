import { useEffect, useState } from "react";
import QuizTimer from "./QuizTimer";
import { useRef } from "react";
import QuestionImg from "./QuestionImg";
import { endpoints } from "../../hooks/endpoints";
import { invokeUserRefetch } from "../../store/slices/storeSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const Quiz = ({ lessonData, setQuizRequired }) => {
  const [startQuiz, setStartQuiz] = useState(false);
  const [quizResult, setqResult] = useState();
  const [quizScore, setQuizScore] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [quizEnded, setQuizEnded] = useState(false);
  const [quizExpired, setQuizExpired] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);
  const quizForm = useRef();
  const scoreDiv = useRef();
  const dispatch = useAppDispatch();

  const { currentTeacher } = useAppSelector((state) => state.store);

  useEffect(() => {
    const currentTimestamp = Date.now();
    const quizExpiryTimestamp = Date.parse(lessonData.last_trial_date);
    if (currentTimestamp > quizExpiryTimestamp) {
      setQuizExpired(true);
    }
    if (
      lessonData.past_quiz_trials &&
      lessonData.past_quiz_trials[0]?.raw_data &&
      lessonData.past_quiz_trials[0]?.raw_data[0] !== ""
    ) {
      setStartQuiz(true);
      const qRes =
        JSON.parse(lessonData.past_quiz_trials[0]?.raw_data[0]) ??
        JSON.parse("{}");
      setPercentage((Number(qRes.score) / Number(qRes.maxScore)) * 100);
      setqResult(qRes);
      setQuizRequired(0);
      setQuizScore(`${qRes.score} من ${qRes.maxScore}`);
      setQuizEnded(true);
      setTimeout(() => {
        lessonData.quiz_questions.map((q) => {
          const ans = qRes[q.question_id].answer;
          const el = document.getElementById(`btnradio-${q.title}-${ans}`);
          if (el) {
            el.checked = true;
          }
        });
      }, 1500);
    }
  }, [lessonData]);

  const shuffleQuestions = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const questionsData =
    lessonData.quiz_randomize === "on"
      ? shuffleQuestions(lessonData.quiz_questions)
      : lessonData.quiz_questions;

  const handleStartQuiz = () => {
    setStartQuiz(true);
    if (!localStorage.getItem(`quiz_${lessonData.quiz_id}_start_date`))
      localStorage.setItem(`quiz_${lessonData.quiz_id}_start_date`, Date.now());
  };

  useEffect(() => {
    if (localStorage.getItem(`quiz_${lessonData.quiz_id}_start_date`))
      handleStartQuiz();
  }, []);

  const calculateRemainigTime = () => {
    // Get the difference in milliseconds
    const differenceInMilliseconds = Math.abs(
      Date.now() -
        Number(localStorage.getItem(`quiz_${lessonData.quiz_id}_start_date`))
    );

    // Convert the difference to seconds
    const differenceInSeconds = differenceInMilliseconds / 1000;

    return Math.ceil(lessonData.quiz_duration - differenceInSeconds);
  };

  console.log("remtime", calculateRemainigTime());

  const handleSubmitQuiz = async (e) => {
    if (e) {
      e.preventDefault();
    }
    localStorage.removeItem(`quiz_${lessonData.quiz_id}_start_date`);
    setLoading(true);
    const formData = new FormData(quizForm.current);
    const formDataObject = {};
    for (const [key, value] of formData.entries()) {
      formDataObject[key] = value;
    }
    formDataObject["quiz_id"] = lessonData.quiz_id;
    const qResult = { score: 0, maxScore: 0 };
    lessonData.quiz_questions.map((question) => {
      if (formDataObject[question.question_id] === question.correct_answer) {
        qResult[question.question_id] = {
          correct: true,
          answer: formDataObject[question.question_id],
        };
        qResult["score"] = Number(qResult["score"]) + Number(question.weight);
      } else {
        qResult[question.question_id] = {
          correct: false,
          answer: formDataObject[question.question_id],
        };
      }
      qResult["maxScore"] =
        Number(qResult["maxScore"]) + Number(question.weight);
    });
    // setPercentage((Number(qResult.score) / Number(qResult.maxScore)) * 100);
    // setqResult(qResult);
    // if (qResult) {
    //   setQuizRequired(0);
    // }
    // setQuizScore(`${qResult.score} من ${qResult.maxScore}`);
    // setQuizEnded(true);
    // scoreDiv.current.scrollIntoView();
    const user = JSON.parse(
      localStorage.getItem(`${window.location.hostname}_user`)
    );
    // const {score, maxScore, ...answers} = qResult
    const answers = [];
    lessonData.quiz_questions.map((question) => {
      answers.push(
        `${question.question_id}-${qResult[question.question_id].answer}-${
          qResult[question.question_id].correct
        }`
      );
    });
    fetch(endpoints.submitQuiz, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        title: `${lessonData.title}-${lessonData.quiz_id}-${lessonData.quiz_title}-${user.user_display_name}`,
        quiz_id: lessonData.quiz_id,
        quiz: `${lessonData.quiz_title}-${lessonData.quiz_id}`,
        student: `${user.user_display_name}-${user.user_email}`,
        grade: qResult.score,
        raw_data: JSON.stringify(qResult),
        answers: answers.join(","),
        teacher: currentTeacher,
        lesson_id: lessonData.lesson_id
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.message === "success") {
          setPercentage(
            (Number(qResult.score) / Number(qResult.maxScore)) * 100
          );
          setqResult(qResult);
          if (qResult) {
            setQuizRequired(0);
          }
          setQuizScore(`${qResult.score} من ${qResult.maxScore}`);
          setQuizEnded(true);
          scoreDiv.current.scrollIntoView();
          dispatch(invokeUserRefetch());
          setLoading(false);
        } else {
          setSubmitError(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setSubmitError(true);
        setLoading(false);
      });
  };

  return (
    <div>
      <div ref={scoreDiv}></div>
      {quizScore && lessonData.quiz_id && (
        <div className="p-3">
          <div className="d-flex">
            <img
              className="image-fluid w-50 mx-auto"
              src="https://api.eliteacademyeg.com/wp-content/uploads/2024/01/exam-finished-1.png"
              alt=""
            />
          </div>
          <div className="d-flex mb-3">
            <h3 className="m-auto">درجتك في الكويز</h3>
          </div>
          {/* <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow={75}
            aria-valuemin={0}
            aria-valuemax={100}
          /> */}
          <div className="d-flex">
            <div
              className="progress rounded-ea responsive-quiz-grade mx-auto"
              style={{ height: "1.5rem" }}
            >
              <div
                className="progress-bar rounded-ea"
                role="progressbar"
                style={{ width: `${percentage}%`, height: "1.5rem" }}
                aria-valuenow={percentage}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <p className="fw-bold my-auto fs-5">{quizScore}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {!startQuiz &&
      !lessonData.past_quiz_trials &&
      lessonData.quiz_id &&
      !quizExpired ? (
        <div className="p-5">
          <div className="w-100 d-flex mb-2">
            <p className="d-inline m-auto">
              المدة : {lessonData.quiz_duration / 60} دقيقة
            </p>
          </div>
          <div className="w-100 d-flex">
            <button
              className="btn ea-btn m-auto rounded-ea px-4 py-2"
              onClick={handleStartQuiz}
            >
              إبدأ الكويز
            </button>
          </div>
        </div>
      ) : !startQuiz &&
        !lessonData.past_quiz_trials &&
        !lessonData.quiz_id &&
        !quizExpired ? (
        <div className="p-5 d-flex">
          <h2 className="m-auto">لا يوجد كويز</h2>
        </div>
      ) : quizExpired && !lessonData.past_quiz_trials ? (
        <div className="alert alert-danger m-5 rounded-ea" role="alert">
          إنتهى الوقت المسموح لدخول الكويز , تواصل مع المدرس
        </div>
      ) : (
        ""
      )}
      {startQuiz && (
        <>
          {!quizScore && (
            <div
              className="mt-3 mx-4 alert alert-warning d-flex rounded-ea border-warning"
              role="alert"
            >
              <h5 className="m-auto d-inline">
                <QuizTimer
                  handleSubmitQuiz={handleSubmitQuiz}
                  seconds={
                    !localStorage.getItem(
                      `quiz_${lessonData.quiz_id}_start_date`
                    )
                      ? lessonData.quiz_duration
                      : calculateRemainigTime()
                  }
                />
              </h5>
            </div>
          )}
          <form
            ref={quizForm}
            className="quiz-form"
            onSubmit={(e) => handleSubmitQuiz(e)}
          >
            {lessonData &&
              questionsData.map((question) => (
                <div
                  key={question.question_id}
                  className={`p-2 bg-light mb-3 mx-4 border-ea-2 rounded-ea ${
                    quizEnded ? "quiz-ended" : ""
                  }`}
                >
                  <div className="rounded-ea d-flex">
                    {/* <img
                      width="100%"
                      src={lessonData && question.img}
                      className="m-auto rounded-3 img-fluid"
                      alt={lessonData && question.title}
                    /> */}
                    {question.img && (
                      <QuestionImg
                        src={lessonData && question.img}
                        alt={lessonData && question.title}
                      />
                    )}
                  </div>
                  {lessonData && question.content ? (
                    <>
                      {question.content.indexOf("\n") !== 1 ? (
                        question.content
                          .split("\n")
                          .map((p: string) => <p>{p}</p>)
                      ) : (
                        <p>{question.content}</p>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  <div
                    className="my-2 btn-group w-100"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    {question.answers.map((answer, index) => (
                      <>
                        <input
                          type="radio"
                          className="btn-check"
                          name={question.question_id}
                          id={`btnradio-${question.title}-${
                            question.answers[
                              question.answers.length - (index + 1)
                            ]
                          }`}
                          value={
                            question.answers[
                              question.answers.length - (index + 1)
                            ]
                          }
                          autoComplete="off"
                          required={
                            lessonData.must_answer_all === "on" ? true : false
                          }
                        />
                        <label
                          className="btn btn-outline-primary fw-bold"
                          htmlFor={`btnradio-${question.title}-${
                            question.answers[
                              question.answers.length - (index + 1)
                            ]
                          }`}
                        >
                          {lessonData &&
                            question.answers[
                              question.answers.length - (index + 1)
                            ]}
                        </label>
                      </>
                    ))}
                  </div>
                  {quizResult && quizResult[question.question_id].correct && (
                    <div className="d-flex">
                      <div
                        style={{ maxHeight: "50px" }}
                        className="d-flex py-2 px-4 rounded-ea alert alert-success mx-auto"
                        role="alert"
                      >
                        <p className="fw-bold m-auto">إجابة صحيحة</p>
                      </div>
                    </div>
                  )}
                  {quizResult && !quizResult[question.question_id].correct && (
                    <div className="d-flex">
                      <div
                        style={{ maxHeight: "50px" }}
                        className="d-felx py-2 px-4 rounded-ea alert alert-danger mx-auto"
                        role="alert"
                      >
                        <p className="fw-bold m-auto">
                          {lessonData.hide_correct_answers === "on"
                            ? "إجابة خاطئة"
                            : `الإجابة الصحيحة هي ${question.correct_answer}`}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            <div className="d-flex py-2 px-3">
              {!quizScore && !loading && (
                <input
                  type="submit"
                  className="btn ea-btn rounded-ea w-100 mx-auto"
                  value="تسليم الامتحان"
                />
              )}
              {loading && (
                <button
                  className="btn ea-btn rounded-ea w-100 mx-auto"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  تسليم الامتحان...
                </button>
              )}
            </div>
            {submitError && (
              <div className="alert alert-danger mx-3 rounded-ea" role="alert">
                حدث خطأ أثناء تسليم الامتحان , تواصل مع الدعم الفني
              </div>
            )}
          </form>
        </>
      )}
    </div>
  );
};

export default Quiz;
