import { useEffect, useRef, useState } from "react";
import { baseUrl } from "../../hooks/BaseUrl";
import Spinner from "../Spinner";
import FileUploadProgressBar from "./FileUploadProgressBar";
import QuestionImg from "../quiz/QuestionImg";
import Animation from "../Animation";
import { getFileUrl, getToken } from "../../hooks/utils";
import { endpoints } from "../../hooks/endpoints";

import { invokePortalRefetch } from "../../store/slices/storeSlice";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../App";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const EditQuiz = () => {
  const [quizType, setQuizType] = useState("quizType");
  const [selectedQuiz, setSelectedQuiz] = useState("noQuizId");
  const [quesData, setQuesData] = useState({});

  const [questionsCount, setQuestionsCount] = useState([1]);
  const [questionsImages, setQuestionsImages] = useState({});
  const [successMsg, setSuccessMsg] = useState(null);
  const [publishing, setPublishing] = useState(false);
  const [fileProgress, setFileProgress] = useState(null);
  const hideCorrectAnswers = useRef();
  const randomizeQuestions = useRef();
  const mustAnswerAll = useRef();

  const [lessonFiles, setLessonFiles] = useState([]);

  const [loading, setLoading] = useState(false);
  // const [quizes, setQuizes] = useState(null);
  const [quizData, setQuizData] = useState(null);

  const [availableAnswers, setAvailableAnswers] = useState({});

  const { userProfileData } = useAppSelector((state) => state.store);
  const quizes = userProfileData?.hws_quizzes_lessons?.filter((item) =>
    ["quiz", "homework"].includes(item.post_type)
  );
  const dispatch = useAppDispatch();

  const getAnswersCount = (id) => {
    const answers = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
    const answersCount = availableAnswers[id];
    const answersOptions = [];
    for (let i = 0; i < answersCount && i < 10; i++) {
      answersOptions.push(answers[i]);
    }
    return answersOptions;
  };

  const maxMinCount = (e, num) => {
    if (e.target.value > 10) {
      const obj = {};
      obj[`quiz_question_ans_count_${num}`] = 10;
      setAvailableAnswers((prev) => ({ ...prev, [e.target.id]: 10 }));
      setQuesData((prev) => ({ ...prev, ...obj }));
      document.getElementById(e.target.id).value = 10;
    } else if (e.target.value < 2) {
      const obj = {};
      obj[`quiz_question_ans_count_${num}`] = 2;
      setAvailableAnswers((prev) => ({ ...prev, [e.target.id]: 2 }));
      setQuesData((prev) => ({ ...prev, ...obj }));
      document.getElementById(e.target.id).value = 2;
    } else {
      const obj = {};
      obj[`quiz_question_ans_count_${num}`] = e.target.value;
      setAvailableAnswers((prev) => ({
        ...prev,
        [e.target.id]: e.target.value,
      }));
      setQuesData((prev) => ({ ...prev, ...obj }));
    }
  };

  const handeGetQuizData = (e) => {
    setQuestionsCount([1]);
    setQuestionsImages({});
    setQuesData({});
    setQuizData(null);
    setSuccessMsg(null);
    setSelectedQuiz(e.target.value);
    setLoading(true);
    fetch(`${endpoints.editGetQuiz}?quiz=${e.target.value}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        console.log("quizDataTest", data);
        setQuizData(data);
        setQuesData((prev) => ({
          ...prev,
          title: quizes?.filter((q) => q.ID === e.target.value)[0].post_title,
          pass_score: data.data.pass_grade ? +data.data.pass_grade[0] : 0,
          time_minutes: data.data.time_minutes
            ? +data.data.time_minutes[0] / 60
            : 0,
          reorder_questions:
            data.data.reorder_questions[0] === "on" ? true : false,
          must_answer_all: data.data.must_answer_all[0] === "on" ? true : false,
          hide_correct_answers:
            data.data.hide_correct_answers[0] === "on" ? true : false,
        }));
        setQuestionsCount([...data.questions_data]);
        data.questions_data.forEach((q, index) => {
          const obj1 = {};
          obj1[`quiz_question_weight_${index}`] = q.weight[0];
          obj1[`quiz_question_ans_${index}`] = q.correct_answer[0];
          obj1[`quiz_question_ans_count_${index}`] = q.answers_count[0]
            ? q.answers_count[0]
            : "4";
          setQuesData((prev) => ({ ...prev, ...obj1 }));
          //   weightElement.value = +q.weight[0];
          //   const correctAnsElement = document.getElementById(
          //     `quiz_question_ans_${index}`
          //   );
          //   correctAnsElement.value = q.correct_answer[0];
          const obj2 = {};
          obj2[`quiz_question_img_url_${index}`] = q.image_url[0];
          setQuestionsImages((prev) => ({ ...prev, ...obj2 }));
        });
      })
      .catch((err) => {
        setQuizData(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log("fdsafadsfdasf", quesData);
  }, [quesData]);

  // useEffect(() => {
  //   fetch(`${endpoints.teacherQuizzes}?timestamp=${Date()}`, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: getToken(),
  //     },
  //     method: "GET",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setQuizes(data);
  //     })
  //     .catch((err) => {
  //       setQuizes(null);
  //       console.log(err);
  //     });
  // }, []);

  //   const handleUpload = async (e) => {
  //     if (!e.target.files[0]) {
  //       alert("Please select a file.");
  //       return;
  //     }

  //     try {
  //       const formData = new FormData();
  //       formData.append("file", e.target.files[0], e.target.files[0].name);

  //       const xhr = new XMLHttpRequest();

  //       // Track the progress of the upload
  //       xhr.upload.addEventListener("progress", (event) => {
  //         if (event.lengthComputable) {
  //           const percentComplete = (event.loaded / event.total) * 100;
  //           console.log(
  //             "uploadProgress",
  //             `Upload Progress: ${percentComplete.toFixed(2)}%`
  //           );
  //           if (percentComplete.toFixed(2) === "100.00") {
  //             setFileProgress(null);
  //           } else {
  //             setFileProgress(percentComplete.toFixed(2));
  //           }
  //           // You can update a progress bar or display the percentage as needed
  //         }
  //       });

  //       xhr.open("POST", endpoints.drive, true);
  //       // xhr.open("POST", endpoints.eliteUpload, true);

  //       xhr.onreadystatechange = function () {
  //         if (xhr.readyState === XMLHttpRequest.DONE) {
  //           if (xhr.status === 200) {
  //             try {
  //               const data = JSON.parse(xhr.responseText);

  //               if (data.success) {
  //                 // File uploaded successfully
  //                 setLessonFiles((prev) => [...prev, data.url]);
  //                 console.log(data);
  //                 setFileProgress(null);
  //               } else {
  //                 // File upload failed
  //                 alert("File upload failed. " + data.message);
  //                 setFileProgress(null);
  //               }
  //             } catch (error) {
  //               alert("حدث خطأ");
  //               setFileProgress(null);
  //             }
  //           } else {
  //             // Server error
  //             alert("حدث خطأ");
  //             setFileProgress(null);
  //           }
  //         }
  //       };

  //       xhr.send(formData);
  //     } catch (error) {
  //       // Network error
  //       alert("An error occurred while uploading the file.");
  //     }
  //   };

  const handleUploadFb = (e) => {
    if (!e.target.files[0]) {
      alert("Please select a file.");
      return;
    }

    const imageRef = ref(storage, `questions-${new Date().getTime()}`);
    const uploadTask = uploadBytesResumable(imageRef, e.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setFileProgress(progress.toFixed(2));
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        console.log(error);
        alert("حدث خطأ");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // File uploaded successfully
          const tempObject = {};
          tempObject[`quiz_question_img_url_${e.target.dataset.id}`] =
            getFileUrl(downloadURL);
          setQuestionsImages({ ...questionsImages, ...tempObject });
          setFileProgress(null);
        });
      }
    );
  };

  const handleUpload = async (e) => {
    if (!e.target.files[0]) {
      alert("Please select a file.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0], e.target.files[0].name);

      const xhr = new XMLHttpRequest();

      // Track the progress of the upload
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          console.log(
            "uploadProgress",
            `Upload Progress: ${percentComplete.toFixed(2)}%`
          );
          if (percentComplete.toFixed(2) === "100.00") {
            setFileProgress(null);
          } else {
            setFileProgress(percentComplete.toFixed(2));
          }
          // You can update a progress bar or display the percentage as needed
        }
      });

      xhr.open("POST", endpoints.aws, true);
      // xhr.open("POST", endpoints.imageUpload, true);
      // xhr.open("POST", endpoints.drive, true);

      // Add the Authorization header with the token
      const token = JSON.parse(
        localStorage.getItem(`${window.location.hostname}_user`)
      ).token;
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            try {
              const data = JSON.parse(xhr.responseText);

              if (data.success) {
                // File uploaded successfully
                const tempObject = {};
                tempObject[`quiz_question_img_url_${e.target.dataset.id}`] =
                  data.url;
                setQuestionsImages({ ...questionsImages, ...tempObject });
                console.log(data);
                setFileProgress(null);
              } else {
                // File upload failed
                alert("File upload failed. " + data.message);
                setFileProgress(null);
              }
            } catch (error) {
              alert("حدث خطأ");
              setFileProgress(null);
            }
          } else {
            // Server error
            alert("حدث خطأ");
            setFileProgress(null);
          }
        }
      };

      xhr.send(formData);
    } catch (error) {
      // Network error
      alert("An error occurred while uploading the file.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPublishing(true);
    try {
      const formData = new FormData(e.target);
      const formDataObject = {};
      for (const [key, value] of formData.entries()) {
        formDataObject[key] = value;
      }
      formDataObject["questionsCount"] = questionsCount;
      formDataObject["hideCorrectAnswers"] = hideCorrectAnswers.current.checked;
      formDataObject["randomizeQuestions"] = randomizeQuestions.current.checked;
      formDataObject["mustAnswerAll"] = mustAnswerAll.current.checked;
      formDataObject["quizId"] = selectedQuiz;

      const response = await fetch(endpoints.editQuiz, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem(`${window.location.hostname}_user`))
              .token
          }`,
        },
        body: JSON.stringify(formDataObject),
      });

      if (!response.ok) {
        // Handle server errors or other issues
        console.error("Error submitting the form");
        return;
      } else {
        setPublishing(false);
        setSuccessMsg(true);
        dispatch(invokePortalRefetch());
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Animation>
      <div className="create-lesson">
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="m-3 p-3 bg-white rounded-ea shadow"
        >
          <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
            تعديل إمتحان / واجب
          </h3>
          <select
            onChange={(e) => {
              setQuizType(e.target.value);
              setSelectedQuiz("noQuizId");
            }}
            className="form-select rounded-ea my-3"
            aria-label="Default select example"
          >
            <option value="quizType" selected>
              نوع الإمتحان
            </option>
            <option value="quiz">كويز</option>
            <option value="homework">واجب</option>
          </select>
          {quizType !== "quizType" && (
            <select
              value={selectedQuiz}
              onChange={handeGetQuizData}
              className="form-select rounded-ea my-3"
              aria-label="Default select example"
            >
              <option value="noQuizId" selected>
                إختر الإمتحان
              </option>
              {quizes &&
                quizes
                  ?.filter((q) => q.post_type === quizType)
                  ?.map((quiz) => (
                    <option value={quiz.ID}>{quiz.post_title}</option>
                  ))}
            </select>
          )}
          {selectedQuiz !== "noQuizId" &&
          quizType !== "quizType" &&
          !loading ? (
            <>
              <div className="form-floating mb-3">
                <input
                  value={quesData.title}
                  onChange={(e) => {
                    setQuesData((prev) => ({ ...prev, title: e.target.value }));
                  }}
                  type="text"
                  className="form-control rounded-ea"
                  id="title"
                  name="title"
                  placeholder="title"
                  // disabled
                  required
                />
                <label htmlFor="title">عنوان الإمتحان</label>
              </div>
              {/* <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control rounded-ea"
                id="description"
                name="description"
                placeholder="description"
                required
              />
              <label htmlFor="description">رسالة قبل بدء الإمتحان</label>
            </div> */}
              {quizType === "quiz" && (
                <div className="form-floating mb-3">
                  <input
                    value={quesData.pass_score}
                    onChange={(e) => {
                      setQuesData((prev) => ({
                        ...prev,
                        pass_score: e.target.value,
                      }));
                    }}
                    type="number"
                    className="form-control rounded-ea"
                    id="pass_score"
                    name="pass_score"
                    placeholder="pass_score"
                    required
                  />
                  <label htmlFor="pass_score">درجة النجاح</label>
                </div>
              )}
              {/* <div className="form-floating mb-3">
              <input
                type="number"
                className="form-control rounded-ea"
                id="quiz_max_redo_count"
                name="quiz_max_redo_count"
                placeholder="quiz_max_redo_count"
                required
              />
              <label htmlFor="quiz_max_redo_count">
                عدد مرات دخول الامتحان للطالب
              </label>
            </div> */}
              {quizType === "quiz" && (
                <div className="form-floating mb-3">
                  <input
                    value={quesData.time_minutes}
                    onChange={(e) => {
                      setQuesData((prev) => ({
                        ...prev,
                        time_minutes: +e.target.value,
                      }));
                    }}
                    type="number"
                    className="form-control rounded-ea"
                    max={360}
                    min={0}
                    id="timer"
                    name="timer"
                    placeholder="timer"
                    required
                  />
                  <label htmlFor="timer">الوقت (بالدقائق)</label>
                </div>
              )}
              <div className="form-floating mb-3">
                <input
                  value={
                    quizData.data && quizData.data.last_trial_date
                      ? quizData.data.last_trial_date[0]
                      : ""
                  }
                  onChange={(e) => {
                    setQuizData((prev) => ({
                      ...prev,
                      data: { ...prev.data, last_trial_date: e.target.value },
                    }));
                  }}
                  type="datetime-local"
                  className="form-control rounded-ea"
                  id="last_trial_date"
                  name="last_trial_date"
                  placeholder="last_trial_date"
                />
                <label htmlFor="last_trial_date">
                  اخر ميعاد لدخول الإمتحان (إختياري)
                </label>
              </div>
              <div className="form-check form-switch my-3">
                <input
                  onChange={(e) => {
                    setQuesData((prev) => ({
                      ...prev,
                      hide_correct_answers: e.target.checked,
                    }));
                  }}
                  checked={quesData.hide_correct_answers}
                  ref={hideCorrectAnswers}
                  className="form-check-input"
                  type="checkbox"
                  id="hide_correct_answers"
                />
                <label
                  className="form-check-label ms-1"
                  htmlFor="hide_correct_answers"
                >
                  إخفاء الإجابات الصحيحة
                </label>
              </div>
              <div className="form-check form-switch my-3">
                <input
                  onChange={(e) => {
                    setQuesData((prev) => ({
                      ...prev,
                      reorder_questions: e.target.checked,
                    }));
                  }}
                  checked={quesData.reorder_questions}
                  ref={randomizeQuestions}
                  className="form-check-input"
                  type="checkbox"
                  id="randomize_questions"
                />
                <label
                  className="form-check-label ms-1"
                  htmlFor="randomize_questions"
                >
                  إعادة ترتيب الأسئلة لكل طالب
                </label>
              </div>
              <div className="form-check form-switch my-3">
                <input
                  onChange={(e) => {
                    setQuesData((prev) => ({
                      ...prev,
                      must_answer_all: e.target.checked,
                    }));
                  }}
                  checked={quesData.must_answer_all}
                  ref={mustAnswerAll}
                  className="form-check-input"
                  type="checkbox"
                  id="must_answer_all"
                />
                <label
                  className="form-check-label ms-1"
                  htmlFor="must_answer_all"
                >
                  يجب حل جميع الأسئلة
                </label>
              </div>
              {questionsCount?.map((question, index) => (
                <>
                  <label
                    className="form-label"
                    htmlFor={`quiz_question_img_input_${index}`}
                  >
                    صورة السؤال
                  </label>
                  <div>
                    {fileProgress &&
                      fileProgress < 100 &&
                      fileProgress >= 0 &&
                      index + 1 === questionsCount.length && (
                        <FileUploadProgressBar fileProgress={fileProgress} />
                      )}

                    <input
                      id={`quiz_question_img_input_${index}`}
                      onChange={(e) => handleUploadFb(e)}
                      data-id={index}
                      className="elite-file lesson_form_input form-control mt-2"
                      type="file"
                      name={`quiz_question_img_input_${index}`}
                      // required
                    />
                    <br />
                  </div>
                  {questionsImages[`quiz_question_img_url_${index}`] && (
                    <QuestionImg
                      id={`uploaded-image-${index}`}
                      src={questionsImages[`quiz_question_img_url_${index}`]}
                      alt=""
                      classStyles="m-auto mb-2 img-fluid rounded-ea"
                      border="3px solid #673de6"
                    />
                  )}
                  <div
                    id={`uploaded-image-spinner-${index}`}
                    className="spinner-border spinner"
                    role="status"
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <input
                    id={`quiz_question_img_url_${index}`}
                    value={questionsImages[`quiz_question_img_url_${index}`]}
                    className="lesson_form_input form-control question_counter"
                    type="hidden"
                    name={`quiz_question_img_url_${index}`}
                    required
                  />
                  {/*  */}
                  <div className="form-floating mb-3">
                    <input
                      type="number"
                      className="form-control rounded-ea"
                      id={`quiz_question_ans_count_${index}`}
                      name={`quiz_question_ans_count_${index}`}
                      placeholder={`quiz_question_ans_count_${index}`}
                      value={quesData[`quiz_question_ans_count_${index}`]}
                      max={10}
                      min={2}
                      required
                      onChange={(e) => maxMinCount(e, index)}
                    />
                    <label htmlFor={`quiz_question_ans_count_${index}`}>
                      عدد الإجابات
                    </label>
                  </div>
                  <select
                    value={quesData[`quiz_question_ans_${index}`]}
                    onChange={(e) => {
                      const obj = {};
                      obj[`quiz_question_ans_${index}`] = e.target.value;
                      setQuesData((prev) => ({ ...prev, ...obj }));
                    }}
                    id={`quiz_question_ans_${index}`}
                    className="form-select mb-3 rounded-ea"
                    name={`quiz_question_ans_${index}`}
                    aria-label="Default select example"
                    required
                  >
                    <option value="">الإجابة الصحيحة</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option
                      value="C"
                      style={{
                        display:
                          quesData[`quiz_question_ans_count_${index}`] < 3
                            ? "none"
                            : "",
                      }}
                    >
                      C
                    </option>
                    <option
                      value="D"
                      style={{
                        display:
                          quesData[`quiz_question_ans_count_${index}`] < 4
                            ? "none"
                            : "",
                      }}
                    >
                      D
                    </option>
                    <option
                      value="E"
                      style={{
                        display:
                          quesData[`quiz_question_ans_count_${index}`] < 5
                            ? "none"
                            : "",
                      }}
                    >
                      E
                    </option>
                    <option
                      value="F"
                      style={{
                        display:
                          quesData[`quiz_question_ans_count_${index}`] < 6
                            ? "none"
                            : "",
                      }}
                    >
                      F
                    </option>
                    <option
                      value="G"
                      style={{
                        display:
                          quesData[`quiz_question_ans_count_${index}`] < 7
                            ? "none"
                            : "",
                      }}
                    >
                      G
                    </option>
                    <option
                      value="H"
                      style={{
                        display:
                          quesData[`quiz_question_ans_count_${index}`] < 8
                            ? "none"
                            : "",
                      }}
                    >
                      H
                    </option>
                    <option
                      value="I"
                      style={{
                        display:
                          quesData[`quiz_question_ans_count_${index}`] < 9
                            ? "none"
                            : "",
                      }}
                    >
                      I
                    </option>
                    <option
                      value="J"
                      style={{
                        display:
                          quesData[`quiz_question_ans_count_${index}`] < 10
                            ? "none"
                            : "",
                      }}
                    >
                      J
                    </option>
                  </select>
                  {/*  */}
                  <div className="form-floating mb-3">
                    <input
                      value={quesData[`quiz_question_weight_${index}`]}
                      onChange={(e) => {
                        const obj = {};
                        obj[`quiz_question_weight_${index}`] = e.target.value;
                        setQuesData((prev) => ({ ...prev, ...obj }));
                      }}
                      type="number"
                      min={1}
                      className="form-control rounded-ea"
                      id={`quiz_question_weight_${index}`}
                      name={`quiz_question_weight_${index}`}
                      placeholder={`quiz_question_weight_${index}`}
                      required
                    />
                    <label htmlFor={`quiz_question_weight_${index}`}>
                      الدرجة
                    </label>
                  </div>
                </>
              ))}
              {questionsCount.length > 1 && (
                <div id="remove_question" style={{ marginBottom: "10px" }}>
                  <button
                    disabled={
                      fileProgress && fileProgress < 100 && fileProgress >= 0
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setQuestionsCount((prev) => [
                        ...prev?.filter((val) => val !== prev[prev.length - 1]),
                      ]);
                      questionsImages[
                        `quiz_question_img_url_${questionsCount.length - 1}`
                      ] = null;
                      quesData[
                        `quiz_question_ans_${questionsCount.length - 1}`
                      ] = null;
                      quesData[
                        `quiz_question_weight_${questionsCount.length - 1}`
                      ] = null;
                    }}
                    id="remove_question_btn"
                    className="btn ea-btn rounded-ea"
                    style={{
                      width: "10%",
                      borderRadius: "7px",
                      fontWeight: "bold",
                    }}
                  >
                    -
                  </button>
                </div>
              )}
              <div id="add_question" style={{ marginBottom: "10px" }}>
                <button
                  disabled={
                    fileProgress && fileProgress < 100 && fileProgress >= 0
                      ? true
                      : false
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setQuestionsCount((prev) => [...prev, prev.length + 1]);
                  }}
                  id="add_question_btn"
                  className="btn ea-btn rounded-ea"
                  style={{
                    width: "10%",
                    borderRadius: "7px",
                    fontWeight: "bold",
                  }}
                >
                  +
                </button>
              </div>
              {!publishing && (
                <input
                  type="submit"
                  disabled={
                    fileProgress && fileProgress < 100 && fileProgress >= 0
                      ? true
                      : false
                  }
                  id="publish_new_quiz_submit"
                  className="ea-btn btn w-100 rounded-ea"
                  value="حفظ"
                />
              )}
              {publishing && (
                <button
                  className="ea-btn btn w-100 rounded-ea"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  جاري الحفظ...
                </button>
              )}
              {successMsg && (
                <div className="alert alert-success h-50 mt-2" role="alert">
                  تم تعديل الإمتحان / واجب
                </div>
              )}
            </>
          ) : quizType !== "quizType" && selectedQuiz !== "noQuizId" ? (
            <Spinner />
          ) : (
            ""
          )}
        </form>
      </div>
    </Animation>
  );
};

export default EditQuiz;
