import { useRef } from "react";
import Spinner from "../Spinner";
import { useState } from "react";
import { NavLink as Link } from "react-router-dom";
import Animation from "../Animation";
import { getFileUrl, getToken, getUID } from "../../hooks/utils";
import LoadingButton from "../LoadingButton";
import { endpoints } from "../../hooks/endpoints";
import { useAppSelector } from "../../store/hooks";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../App";

const ManageStudent = () => {
  const [userData, setUserData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isPending2, setIsPending2] = useState(false);
  const [isPending3, setIsPending3] = useState(false);
  const [studentsList, setStudentsList] = useState(null);
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);
  const [userNotFound, setUserNotFound] = useState(null);
  const [changeType, setChangeType] = useState(null);
  const [userChanged, setUserChanged] = useState(null);
  const [userChanged2, setUserChanged2] = useState(null);
  const [newValRef, setNewValRef] = useState(null);
  const [lessonRef, setLessonRef] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [uploadedProfilePic, setUploadedProfilePic] = useState(null);
  const [profilePicState, setProfilePicState] = useState(null);
  const [fileProgress, setFileProgress] = useState(0);
  const studentNumberRef = useRef();
  const fatherPhone = useRef();
  const motherPhone = useRef();
  const city = useRef();
  const school = useRef();
  const { currentTeacher, portal } = useAppSelector((state) => state.store);

  const handleUploadProfilePicFb = async (e) => {
    if (!e.target.files[0]) {
      alert("Please select a file.");
      return;
    }

    setImageLoading(true);

    const chunks = e.target.value.split("\\");
    const file = chunks[chunks.length - 1];
    setProfilePicState(file);

    try {
      const imageRef = ref(storage, `profile_pics-${getUID()}`);
      await uploadBytes(imageRef, e.target.files[0]);
      const url = await getDownloadURL(imageRef);
      setUploadedProfilePic(getFileUrl(url));
      setImageLoading(false);
    } catch (error) {
      alert("حدث خطأ");
    }
  };

  const updateProfileData = () => {
    console.log({userData})
    setIsPending2(true);
    fetch(endpoints.updateProfile, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      method: "POST",
      body: JSON.stringify({
        uid: userData.studentId,
        father_phone: userData?.meta?.father_phone[0],
        mother_phone: userData?.meta?.mother_phone[0],
        city: userData?.meta?.city[0],
        school: userData?.meta?.school[0],
        group: userData?.meta?.[`${window.location.hostname}_group`]?.[0],
        profile_pic: uploadedProfilePic,
        portal: window.location.hostname,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status !== 200) {
          setUserChanged2(res.message);
          setIsPending2(false);
        } else {
          setUserChanged2(res.message);
          setIsPending2(false);
        }
      })
      .catch((err) => {
        setUserChanged2(err.message);
        setIsPending2(false);
      });
  };

  const handleChangeStudent = (e) => {
    e.preventDefault();
    setIsPending(true);
    fetch(
      endpoints.changeStudentData +
        `?timestamp=${Date()}&student=${
          userData.studentId
        }&change=${changeType}&value=${newValRef}&lesson=${lessonRef}&cat=${currentTeacher}&hostname=${
          window.location.hostname
        }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.status !== 200) {
          setUserChanged("bad");
          setIsPending(false);
        } else {
          setUserChanged("good");
          setIsPending(false);
        }
      })
      .catch((err) => {
        setUserChanged("bad");
        setIsPending(false);
      });
  };

  const getUserAndData = (e) => {
    e.preventDefault();
    setIsPending(true);
    setUserChanged(null);
    fetch(
      endpoints.getStudentData +
        `?timestamp=${Date()}&student=${
          studentNumberRef.current.value
        }&teacher=${currentTeacher}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.status !== 200) {
          setUserNotFound("لا يوجد طالب بهذا الرقم");
          setIsPending(false);
        } else {
          setUserData(res);
          setUploadedProfilePic(res?.meta?.profile_pic?.[0]);
          setUserNotFound(null);
          setIsPending(false);
        }
      })
      .catch((err) => {
        setUserNotFound("لا يوجد طالب بهذا الرقم");
        setIsPending(false);
        setError(err);
      });
  };

  const getStudentsList = () => {
    setIsPending3(true);
    fetch(endpoints.studentsList, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setIsPending3(false);
        setStudentsList(data);
      })
      .catch((err) => {
        console.log(err);
        setError2("حدث خطأ");
        setIsPending3(false);
      });
  };

  function downloadJSONAsCSV(jsonData) {
    // Check if data is valid JSON
    if (!jsonData || !Array.isArray(jsonData)) {
      console.error("Invalid JSON data provided.");
      return;
    }

    // Get the headers (assuming all objects have the same keys)
    const headers = Object.keys(jsonData[0]);

    // Create the CSV content
    let csvContent = "";

    // Add the header row
    csvContent += headers.join(",") + "\n";

    // Loop through each object and create a CSV row
    jsonData.forEach(function (row) {
      const data = headers
        .map((header) => {
          // Check if value is string and contains non-ASCII characters
          if (
            typeof row[header] === "string" &&
            /[^\u0000-\u007f]/.test(row[header])
          ) {
            // Quote the value and escape quotes
            return '"' + row[header].replace(/"/g, '""') + '"';
          }
          return JSON.stringify(row[header]);
        })
        .join(",");
      csvContent += data + "\n";
    });

    // Create a Blob object with the CSV data
    const blob = new Blob(["\uFEFF", csvContent], {
      type: "text/csv;charset=utf-8",
    });

    // Create a downloadable link
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "students-list.csv";
    link.style.display = "none"; // Hide the link element

    // Append the link to the document body (for browser compatibility)
    document.body.appendChild(link);

    // Simulate a click on the link to trigger download
    link.click();

    // Remove the link from the document body
    document.body.removeChild(link);
  }

  return (
    <Animation>
      <div className="m-3 p-3 bg-white rounded-ea shadow">
        <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
          إدارة الطلاب
        </h3>
        {/* <button
          className="btn ea-btn mt-3 mb-4 fw-bold rounded-ea"
          onClick={getStudentsList}
          disabled={isPending3}
        >
          عرض جميع الطلاب
        </button> */}
        <LoadingButton
          styles={null}
          handleOnClick={getStudentsList}
          text="عرض جميع الطلاب"
          loading={isPending3}
          additionalClasses="btn ea-btn mt-3 mb-4 fw-bold rounded-ea"
        />
        {studentsList && (
          <>
            <div className="d-flex" style={{ alignItems: "center" }}>
              <p className="fw-bold m-2 h5">
                عدد الطلاب: {Object.keys(studentsList).length}
              </p>
              <button
                className="btn ea-btn mt-1 mb-2 rounded-ea"
                onClick={() => downloadJSONAsCSV(Object.values(studentsList))}
              >
                تحميل شيت إكسل
              </button>
            </div>
            <div className="table-responsive">
              <table className="table table-striped mx-3 my-2">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">الإسم</th>
                    <th scope="col">تليفون</th>
                    {/* <th scope="col">إيميل</th> */}
                    <th scope="col">رقم الأب</th>
                    <th scope="col">رقم الأم</th>
                    <th scope="col">الحصص</th>
                  </tr>
                </thead>
                <tbody>
                  {studentsList &&
                    Object.values(studentsList).map((student, index) => (
                      <tr key={student.uid}>
                        <th style={{ maxWidth: "15px" }} scope="row">
                          {index + 1}
                        </th>
                        <td style={{ maxWidth: "200px" }}>{student.name}</td>
                        <td style={{ maxWidth: "100px" }}>{student.phone}</td>
                        {/* <td>{student.email}</td> */}
                        <td style={{ maxWidth: "100px" }}>
                          {student.father_phone}
                        </td>
                        <td style={{ maxWidth: "100px" }}>
                          {student.mother_phone}
                        </td>
                        <td style={{ maxWidth: "100px" }}>
                          {student.purchase_count}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        <form className="m-3" onSubmit={getUserAndData}>
          <div className="form-floating mb-3">
            <input
              ref={studentNumberRef}
              type="text"
              className="form-control rounded-ea"
              id="studentNumber"
              placeholder="name@example.com"
            />
            <label for="studentNumber">رقم هاتف الطالب</label>
          </div>
          <input type="submit" value="بحث" className="btn ea-btn rounded-ea" />
          {userNotFound && (
            <div
              className="alert alert-danger rounded-ea my-2"
              style={{ width: "20%", minWidth: "200px" }}
              role="alert"
            >
              {userNotFound}
            </div>
          )}
        </form>
        {userData && userData.meta && !userNotFound && (
          <div className="m-3 row">
            {userData &&
              userData.meta.disabled_sites &&
              userData.meta.disabled_sites.filter(
                (site) =>
                  site.toString() === window.location.hostname.toString()
              ).length > 0 && (
                <div
                  className="alert alert-danger rounded-ea w-100"
                  role="alert"
                >
                  تم منع هذا الطالب من الدخول للمنصة
                </div>
              )}
            <div
              className={`text-center m-2 mb-3 ${imageLoading && "loading"}`}
            >
              <img
                onLoad={() => setImageLoading(false)}
                ref={profilePic}
                style={{
                  aspectRatio: 1,
                  width: "12rem",
                  border: "3px solid var(--primary-color)",
                }}
                src={decodeURIComponent(uploadedProfilePic)}
                className="rounded rounded-circle"
                alt="صورة شخصية"
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                الإسم رباعي
              </span>
              <input
                type="text"
                className="form-control"
                placeholder={userData.meta.billing_first_name}
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={userData.meta.billing_first_name}
                contentEditable={false}
              />
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  تليفون
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder={userData.meta.billing_phone}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={userData.meta.billing_phone}
                  contentEditable={false}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  إيميل
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder={userData.meta.billing_email}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={userData.meta.billing_email}
                  contentEditable={false}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  تليفون الأب
                </span>
                <input
                  ref={fatherPhone}
                  value={userData.meta.father_phone}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      meta: { ...userData.meta, father_phone: [e.target.value] },
                    })
                  }
                  type="text"
                  className="form-control"
                  placeholder={userData.meta.father_phone}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  // value={userData.meta.father_phone}
                  // contentEditable={false}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  تليفون الأم
                </span>
                <input
                  ref={motherPhone}
                  value={userData.meta.mother_phone}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      meta: { ...userData.meta, mother_phone: [e.target.value] },
                    })
                  }
                  type="text"
                  className="form-control"
                  placeholder={userData.meta.mother_phone}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  // value={userData.meta.mother_phone}
                  // contentEditable={false}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  المحافظة
                </span>
                <input
                  ref={city}
                  value={userData.meta.city}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      meta: { ...userData.meta, city: [e.target.value] },
                    })
                  }
                  type="text"
                  className="form-control"
                  placeholder={userData.meta.city}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  // value={userData.meta.city}
                  // contentEditable={false}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  المدرسة
                </span>
                <input
                  ref={school}
                  value={userData.meta.school}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      meta: { ...userData.meta, school: [e.target.value] },
                    })
                  }
                  type="text"
                  className="form-control"
                  placeholder={userData.meta.school}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  // value={userData.meta.school}
                  // contentEditable={false}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  المجموعة
                </span>
                <select
                  className="form-select form-select-md"
                  aria-label=".form-select-lg example"
                  value={
                    userData?.meta?.[
                      `${window.location.hostname}_group`
                    ]?.[0] ?? ""
                  }
                  onChange={(e) => {
                    setUserData((prev) => ({
                      ...prev,
                      meta: {
                        ...prev.meta,
                        [`${window.location.hostname}_group`]: [e.target.value],
                      },
                    }));
                  }}
                  required
                >
                  <option value="" selected>
                    إختر المجموعة
                  </option>
                  {(portal?.offline_groups?.length > 0
                    ? portal?.offline_groups
                    : ["أونلاين"]
                  ).map((group: string) => (
                    <option value={group}>{group}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <label
                  id="profilePic"
                  className="input-group-text"
                  for="inputGroupFile01"
                >
                  صورة شخصية
                </label>
                <input
                  onChange={(e) => handleUploadProfilePicFb(e)}
                  type="file"
                  className="form-control border d-none"
                  id="inputGroupFile01"
                />
                <div
                  id="profilePicDiv"
                  className="form-control"
                  onClick={() => {
                    const profilePicInput =
                      document.getElementById("profilePic");
                    profilePicInput.click();
                  }}
                >
                  {fileProgress === null || fileProgress === 0 ? (
                    profilePicState
                  ) : (
                    <div
                      style={{
                        width: fileProgress + "%",
                        height: "100%",
                        backgroundColor: "green",
                      }}
                    ></div>
                  )}
                </div>
              </div>
            </div>
            <div className="my-2">
              <button
                type="button"
                className="btn ea-btn rounded-ea"
                onClick={updateProfileData}
                disabled={isPending2}
              >
                {isPending2
                  ? "تعديل البيانات الأساسية ..."
                  : "تعديل البيانات الأساسية"}
              </button>
            </div>
            {userChanged2 && (
              <div
                className={`ms-2 alert alert-${
                  userChanged2.indexOf("تم") > -1 ? "success" : "danger"
                } rounded-ea`}
                style={{ width: "fit-content" }}
              >
                {userChanged2}
              </div>
            )}
            <div className="d-flex my-2">
              <p
                className="text-ea-primary fw-bold h5 m-auto"
                style={{ textAlign: "center" }}
              >
                عمليات الشراء
              </p>
            </div>
            {userData.invoices && (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">رقم العملية</th>
                    <th scope="col">التاريخ</th>
                    <th scope="col">الحالة</th>
                    <th scope="col">الإجمالي</th>
                    <th scope="col">تفاصيل</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.invoices &&
                    userData.invoices.map((invoice, index) => (
                      <tr>
                        <th scope="row">{invoice.id}</th>
                        <td>{invoice.date}</td>
                        <td>
                          {invoice.status === "completed" ? (
                            <p className="text-success fw-bold">تم الدفع</p>
                          ) : invoice.status === "pending" ? (
                            <p className="text-danger fw-bold">منتظر الدفع</p>
                          ) : (
                            invoice.status
                          )}
                        </td>
                        <td>{invoice.total} جنيه</td>
                        <td>
                          <Link
                            to={`/invoices/${invoice.id}`}
                            style={{ maxHeight: "30px", padding: "3px 8px" }}
                            className="pt-0 btn ea-btn rounded-ea"
                          >
                            تفاصيل
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            <div className="d-flex my-2">
              <p
                className="text-ea-primary fw-bold h5 m-auto"
                style={{ textAlign: "center" }}
              >
                الإمتحانات
              </p>
            </div>
            {userData.grades && (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">الحصة</th>
                    <th scope="col">الدرجة</th>
                    <th scope="col">التاريخ</th>
                    <th scope="col">تفاصيل</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.grades &&
                    userData.grades.map((grade, index) => (
                      <tr>
                        <th scope="row">{grade.lesson_name}</th>
                        <td>
                          {grade.raw_data !== "" &&
                            `${JSON.parse(grade.raw_data).maxScore} / ${
                              JSON.parse(grade.raw_data).score
                            }`}
                        </td>
                        <td>{grade.date}</td>
                        <td>
                          {grade.raw_data && (
                            <Link
                              to={`/grades/${grade.id}`}
                              style={{ maxHeight: "30px", padding: "3px 8px" }}
                              className="pt-0 btn ea-btn rounded-ea"
                            >
                              تفاصيل
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            <form onSubmit={handleChangeStudent}>
              <select
                value={changeType}
                onChange={(e) => setChangeType(e.target.value)}
                className="form-select mb-3"
                aria-label="Default select example"
              >
                <option selected>تغببر البيانات</option>
                <option value="addView">إضافة مشاهدة</option>
                <option value="remView">خصم مشاهدة</option>
                <option value="addTime">إضافة ساعات لوقت مشاهدة الحصة</option>
                <option value="remTime">خصم ساعات من وقت مشاهدة الحصة</option>
                <option value="passQuiz">تخطي إمتحان</option>
                <option value="passLesson">تخطي حصة</option>
                <option value="disableStudent">
                  منع هذا الطالب من الدخول للمنصة
                </option>
                <option value="enableStudent">
                  السماح للطالب بالدخول للمنصة
                </option>
              </select>
              {(changeType === "addView" ||
                changeType === "remView" ||
                changeType === "addTime" ||
                changeType === "remTime") && (
                <div className="form-floating mb-3">
                  <input
                    onChange={(e) => setNewValRef(e.target.value)}
                    value={newValRef}
                    type="number"
                    className="form-control"
                    id="newValRef"
                    placeholder="name@example.com"
                  />
                  <label htmlFor="newValRef">العدد</label>
                </div>
              )}
              {changeType &&
                changeType !== "disableStudent" &&
                changeType !== "enableStudent" && (
                  <select
                    onChange={(e) => setLessonRef(e.target.value)}
                    value={lessonRef}
                    className="form-select mb-3"
                    aria-label="Default select example"
                  >
                    <option selected>إختر الحصة</option>
                    {userData.myLessons.map((lesson) => (
                      <option value={lesson.id}>{lesson.title}</option>
                    ))}
                  </select>
                )}
              <input
                type="submit"
                value="تغيير"
                className="btn ea-btn rounded-ea"
              />
              {userChanged && (
                <div
                  className={`alert rounded-ea my-3 ${
                    userChanged === "good" ? "alert-success" : "alert-danger"
                  }`}
                  style={{ width: "20%", minWidth: "200px" }}
                  role="alert"
                >
                  {userChanged === "good"
                    ? "تم تغيير البيانات بنجاح"
                    : "حدث خطأ"}
                </div>
              )}
            </form>
          </div>
        )}
        {error && !userNotFound /** && <h2>يجب تسجيل الدخول اولا</h2> */}
        {isPending && <Spinner />}
      </div>
    </Animation>
  );
};

export default ManageStudent;
