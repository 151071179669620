export const checkUnansweredQuestions = (data) => {
    const questions = Object.keys(data).filter(
      (key) => !["score", "maxScore"].includes(key)
    );
    // console.log("questions",questions)
    for (const questionKey of questions) {
      const question = data[questionKey];
      if (!question.hasOwnProperty("answer")) {
        return true; // Student missed a question
      }
    }

    return false; // All questions were answered
  }