export const applyWatermark = () => {
    const userInLocalStorage = JSON.parse(
        localStorage.getItem(`${window.location.hostname}_user`)
      );
    var iframes = document.querySelectorAll("iframe");
    iframes.forEach((iframe) => {
      const watermark = document.createElement("p");
      watermark.textContent = userInLocalStorage.user_email;
      watermark.style.fontSize = "15px";
      watermark.style.position = "absolute";
      watermark.style.bottom = "10%";
      watermark.style.right = "50%";
      watermark.style.zIndex = "1000";
      watermark.style.color = "white";
      watermark.classList.add("shadow");
      watermark.style.opacity = 0.5;
      var parentElement = iframe.parentElement;
      parentElement.appendChild(watermark);
    });
}