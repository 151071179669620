import React from "react";
import VideosMap from "../../../video/VideosMap";

const VideoContent = ({
  devIsOpen,
  setDevIsOpen,
  lessonData,
  isMobile,
  domain,
}) => {
  // if (devIsOpen && !isMobile) {
  //   return (
  //     // <div className="d-flex">
  //     //   <button
  //     //     onClick={() => setDevIsOpen(false)}
  //     //     className="btn ea-btn p-2 h4 w-50 rounded-ea text-white my-3 mx-auto"
  //     //     style={{ textAlign: "center" }}
  //     //   >
  //     //     حدث خطأ في تحميل الفيديو إضغط لإعادة المحاولة
  //     //   </button>
  //     // </div>
  //     <div className="d-flex">
  //     <p
  //       className="p-2 h4 w-50 rounded-ea text-white bg-danger my-3 mx-auto"
  //       style={{ textAlign: "center" }}
  //     >
  //       حدث خطأ في تحميل الفيديو تواصل مع الدعم الفني
  //     </p>
  //   </div>
  //   );
  // }

  return (
    <div>
      {lessonData &&
      lessonData.price !== 0 &&
      lessonData.videos_data &&
      (((lessonData.remaining_views > 0 ||
        lessonData.remaining_views === "infinite") &&
        lessonData.expiry_date > Date.now()) ||
        lessonData.expiry_date === "infinite" ||
        lessonData.is_author) ? (
        <VideosMap
          lessonData={lessonData}
          viewsNotificationMark={lessonData.views_notification_mark}
          expirtDate={lessonData.expiry_date}
          files={lessonData.lesson_files ? lessonData.lesson_files : false}
          protection={lessonData.xvast_protection === "yes" ? true : false}
        />
      ) : lessonData.videos_data === null ? (
        <div className="p-5 d-flex">
          <h2 className="m-auto">لا يوجد فيديو</h2>
        </div>
      ) : (
        <>
          <div className="d-flex">
            <p
              className="p-2 h4 w-50 rounded-ea text-white bg-danger my-3 mx-auto"
              style={{ textAlign: "center" }}
            >
              إنتهت صلاحية الحصة
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default VideoContent;
