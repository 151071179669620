import { useState } from "react";
import CodePurchase from "./purchase_methods/CodePurchase";
import WalletPurchase from "./purchase_methods/WalletPurchase";
import FawryPurchase from "./purchase_methods/FawryPurchase";
import { getFileUrl } from "../../hooks/utils";

const Purchase = ({ lessonData, setData, isMobile }) => {

    const [ purchaseBlock, setPurchaseBlock ] = useState(null);

    return (
        <div className="purchase-lesson">
            <div className="d-flex">
                <img
                className="m-auto w-50"
                src="https://api.eliteacademyeg.com/wp-content/uploads/2024/01/buy-now.png"
                alt="اشتري الان" />
            </div>
            <div className="d-flex my-3">
                <div className="btn-group m-auto w-75 mb-4">
                    <select className="m-auto rounded-ea purchase-options" onChange={(e) => setPurchaseBlock(e.target.value)}>
                        <option selected>اختر وسيلة الدفع</option>
                        {lessonData.code === "yes" && <option value="code">كود</option>}
                        {lessonData.wallet === "yes" && <option value="wallet">رصيد المحفظة</option>}
                        {lessonData.fawry === "yes" && <option value="fawry">فوري</option>}
                        {lessonData.vodafone_cash === "yes" && <option value="vodafoneCash">فودافون كاش</option>}
                    </select>
                </div>
            </div>
            <div className="purchase-block w-100">
                { purchaseBlock === "code" && <CodePurchase lessonData={lessonData} setData={setData} isMobile={isMobile} /> }
                { purchaseBlock === "wallet" && <WalletPurchase lessonData={lessonData} setData={setData} isMobile={isMobile} /> }
                { purchaseBlock === "fawry" && <FawryPurchase lessonData={lessonData} isMobile={isMobile} /> }
            </div>
        </div>
    )
}

export default Purchase