import React from "react";
import { useNavigate } from "react-router-dom";
// import { baseUrl } from './BaseUrl';
// import { useState } from 'react';
// import { useAuthContext } from './useAuthContext'
import { userData } from "../store/slices/storeSlice";

import { signOut } from "firebase/auth";
import { auth } from "../components/App";
import { useAppDispatch } from "../store/hooks";

const Logout = () => {
  // const { dispatch } = useAuthContext()
  const dispatch = useAppDispatch();
  // const [isLogoutPending, setIsLogoutPending] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    // const token = JSON.parse(localStorage.getItem(`${window.location.hostname}_user`)).token
    localStorage.removeItem(`${window.location.hostname}_user`);
    dispatch(userData(null));
    signOut(auth);
    // dispatch({type: 'LOGIN', payload: null})
    navigate("/");
  };

  return (
    <span>
      <button
        className="nav-link fw-bold ea-logout-btn grow"
        onClick={handleLogout}
      >
        الخروج
      </button>
      {/* {!isLogoutPending && (
    <button className="nav-link fw-bold ea-logout-btn grow" onClick={handleLogout}>الخروج</button>
    )}

    {isLogoutPending && (
    <button disabled className="nav-link fw-bold ea-logout-btn text-ea-primary">
    <span
      className="spinner-border spinner-border-sm ms-2 me-2"
      role="status"
      aria-hidden="true"
    ></span>
    جاري الخروج...
  </button>
    )} */}
    </span>
  );
};

export default Logout;
