import React from "react";
import Quiz from "../../../quiz/Quiz";
import Homework from "../../../quiz/Homework";
import VideoContent from "./VideoContent";
import Animation from "../../../Animation";

const LessonContent = ({
  lessonData,
  setQuizRequired,
  isMobile,
  devIsOpen,
  currentContent,
  setDevIsOpen,
}) => {
  const domain = window.location.hostname;
  return (
    <div className="tab-content">
      {/* QUIZ CONTENT */}
      {currentContent === "quiz" && (
        <Animation>
          <div>
            {lessonData &&
              lessonData.price === null &&
              lessonData.price !== 0 && (
                <Quiz
                  lessonData={lessonData}
                  setQuizRequired={setQuizRequired}
                />
              )}
          </div>
        </Animation>
      )}

      {/* VIDEO CONTENT */}
      {currentContent === "video" && (
        <Animation>
          <VideoContent
            devIsOpen={devIsOpen}
            setDevIsOpen={setDevIsOpen}
            lessonData={lessonData}
            isMobile={isMobile}
            domain={domain}
          />
        </Animation>
      )}

      {/* HOMEWORK CONTENT */}
      {currentContent === "hw" && (
        <Animation>
          <div>{lessonData && <Homework lessonData={lessonData} />}</div>
        </Animation>
      )}
    </div>
  );
};

export default LessonContent;
