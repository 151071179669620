import { useState, useEffect } from "react";
import { baseUrl } from "./BaseUrl";
import { getToken } from "./utils";

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // const abortCont = new AbortController();

    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      // signal: abortCont.signal
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch the data");
        }
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setData(data);
        setIsPending(null);
        setError(null);
      })
      .catch((err) => {
        console.log(err);
        if (err.name === "AbortError") {
          console.log("Fetch is aborted");
        } else {
          setError("Could not fetch the data");
          setIsPending(null);
        }
      });

    // return () => abortCont.abort();
  }, [url]);

  return { data, isPending, error, setData, setIsPending };
};

export default useFetch;
