import { createSlice } from "@reduxjs/toolkit";

export interface Portal {
  ID: number;
  bgImg: string;
  category: string;
  logoUrl: string;
  favicon: string;
  name: string;
  cardImg: string;
  allowed_payment_methods: string[];
  portal: string;
  cats: Category[];
  offline_groups: string[];
  data: Lesson[];
}

export interface Category {
  term_id: number;
  name: string;
  slug: string;
  term_group: number;
  term_taxonomy_id: number;
  taxonomy: string;
  description?: string;
  parent: number;
  count: number;
  filter: string;
  order: string;
}

export interface Lesson {
  post_title: string;
  post_name: string;
  _regular_price: string;
  term_taxonomy_id: number;
  term_name: string;
  slug: string;
  post_date: string;
  ID: string;
}

export interface UserData {
  ID: number;
  user_login: string;
  user_email: string;
  display_name: string;
  purchases: any[];
  quizzes: any[] | null;
  homeworks: any[] | null;
  exams: any[] | null;
  disabled_sites: any[] | null;
  school: string;
  city: string;
  father_phone: string;
  mother_phone: string;
  group: string;
  profile_pic: string;
  domain: string;
  teacher_name?: string;
  teacher_subject?: string;
}

const initialState: {
  user: any;
  userProfileData: UserData;
  isTeacher: boolean;
  currentTeacher: string;
  teachers: any;
  wallet: any;
  purchases: number[] | string[] | null;
  portal: Portal;
  portalRefetch: number;
  userRefetch: number;
} = {
  user: null,
  userProfileData: null,
  isTeacher: false,
  currentTeacher: null,
  teachers: null,
  wallet: "",
  purchases: [],
  portal: null,
  portalRefetch: 0,
  userRefetch: 0,
};

export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    userData: (state, action) => {
      state.user = action.payload;
    },
    setUserProfileData: (state, action) => {
      state.userProfileData = action.payload;
    },
    updateUserPurchases: (state, action) => {
      state.purchases = action.payload;
    },
    currentTeacherData: (state, action) => {
      state.currentTeacher = action.payload;
    },
    userIsTeacher: (state, action) => {
      state.isTeacher = action.payload;
    },
    teachersData: (state, action) => {
      state.teachers = action.payload;
    },
    walletData: (state, action) => {
      state.wallet = action.payload;
    },
    setPortal: (state, action) => {
      state.portal = action.payload;
    },
    invokePortalRefetch: (state) => {
      state.portalRefetch += 1;
    },
    invokeUserRefetch: (state) => {
      state.userRefetch += 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  userData,
  teachersData,
  walletData,
  userIsTeacher,
  currentTeacherData,
  updateUserPurchases,
  setPortalData,
  setCats,
  setCatLessons,
  setPortal,
  setUserProfileData,
  invokePortalRefetch,
  invokeUserRefetch,
} = storeSlice.actions;

export default storeSlice.reducer;
