import { baseUrl } from "../../../hooks/BaseUrl";
import { endpoints } from "../../../hooks/endpoints";
import { getToken } from "../../../hooks/utils";

export const handleFreePurchase = async ({
  setData,
  setError,
  setIsPending,
  lesson_id,
}) => {
  setIsPending(true);

  fetch(endpoints.freePurchase + "?lesson=" + lesson_id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken()
    },
  })
    .then((response) => response.json())
    .then((result) => {
      if (result !== "not-free") {
        setData(result);
      } else {
        setError(result);
      }
      setIsPending(false);
    })
    .catch((err) => {
      console.log(err);
      setError("حدث خطأ");
      setIsPending(false);
    });
};
